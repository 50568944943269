









import { Component, Vue, Prop } from 'vue-property-decorator';
import { MultiFileUploaderGenericFileModule } from 'client-website-ts-library/types/Forms/FieldTypes';

@Component
export default class FormMultiFileUploaderGenericFileModule extends Vue {
  @Prop()
  private readonly def!: MultiFileUploaderGenericFileModule;

  private get isMobile(): boolean {
    return /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  }

  private get canUploadImages() {
    return this.def.AcceptableMIMETypes.some((m) => m.indexOf('image/') !== -1);
  }

  private uploadFiles(capture: boolean): void {
    const fi = document.createElement('input') as HTMLInputElement;

    fi.type = 'file';
    fi.accept = this.def.AcceptableMIMETypes.join(',');
    fi.multiple = true;
    fi.style.display = 'none';

    if (capture) {
      fi.setAttribute('capture', 'envionment');
    }

    fi.onchange = () => {
      if (fi.files!.length) {
        this.$emit('add_files', fi.files!);

        fi.remove();
      }
    };

    fi.onblur = () => {
      setTimeout(() => {
        fi.remove();
      }, 100);
    };

    document.body.appendChild(fi);

    fi.click();
  }
}
